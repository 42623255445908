var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"jrF6UD84kzyPyGTwfEbgi"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { ignoreList } from "@/helpers/fetchers/constants";
import * as Sentry from "@sentry/nextjs";

Sentry.init({
  dsn: "https://574d2a0272108f2a02c435285e26c474@o4504253510647808.ingest.us.sentry.io/4507174661521408",

  // ignoreErrors: ignoreList,
  
  // This is for filtering errors out, instead of ignoring
  beforeSend(event, hint) {
    const err: any = hint?.originalException;

    if(!err) return null;
    
    const errFound = ignoreList.find((ignore) => {
      if (ignore instanceof RegExp) {
        return ignore.test(err?.message);
      }
      
      if(err?.message?.match(ignore)) return true;
      if(err?.message?.toUpperCase().match(ignore.toUpperCase())) return true;
      if(err?.message?.toLowerCase().match(ignore.toLowerCase())) return true;
    });

    if(errFound) return null;
    
    return event;
  },

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  replaysOnErrorSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    Sentry.replayIntegration({
      // Additional Replay configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
});
