import(/* webpackMode: "eager" */ "/var/www/dopinghafiza.com/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/var/www/dopinghafiza.com/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/var/www/dopinghafiza.com/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/var/www/dopinghafiza.com/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/var/www/dopinghafiza.com/src/components/Article/Article.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/dopinghafiza.com/src/components/Breadcrumbs/Breadcrumbs.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/dopinghafiza.com/src/components/BuyNowFooter/BuyNowFooter.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/dopinghafiza.com/src/components/CartLink/CartLink.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/dopinghafiza.com/src/components/DPIcon/css/DPIcon.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/dopinghafiza.com/src/components/Footer/Footer.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/dopinghafiza.com/src/components/HeaderContainer/HeaderContainer.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/dopinghafiza.com/src/components/InfoCarousel/InfoCarousel.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/dopinghafiza.com/src/components/InfoCarousel/InfoItem.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/dopinghafiza.com/src/components/LoadingLink/LoadingLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/dopinghafiza.com/src/components/MostPreferredPackages/GroupedPackages.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/dopinghafiza.com/src/components/MostPreferredPackages/SinglePackage.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/dopinghafiza.com/src/components/SideBar/SideBar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/dopinghafiza.com/src/components/StartLiveChat/StartLiveChat.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/dopinghafiza.com/src/components/VideoPlayer/VideoPlayer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/dopinghafiza.com/src/views/blog-category/components/BlogCategoryMain.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/dopinghafiza.com/src/views/blog/blog/components/BlogSubMain.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/dopinghafiza.com/src/views/blog/components/BlogMain.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/dopinghafiza.com/src/views/board/components/BoardMain.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/dopinghafiza.com/src/views/calc_view/components/CalcMain.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/dopinghafiza.com/src/views/contact/components/ContactMain.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/dopinghafiza.com/src/views/faq/components/FaqMain.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/dopinghafiza.com/src/views/faq/faq-categories/components/FaqCategoryMain.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/dopinghafiza.com/src/views/our-packages/components/OurPackagesMain.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/dopinghafiza.com/src/views/our-packages/package-categories/Ilkokul/components/Ilkokul.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/dopinghafiza.com/src/views/our-packages/package-categories/Ilkokul/components/IlkokulFooter.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/dopinghafiza.com/src/views/our-packages/package-categories/packages/components/CustomDopiverseMain.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/dopinghafiza.com/src/views/our-packages/package-categories/packages/components/PackageMain.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/dopinghafiza.com/src/views/our-team/components/OurTeamMain.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/dopinghafiza.com/src/views/our-team/departments/components/DepartmentMain.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/dopinghafiza.com/src/views/scientific/components/ScientificMain.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/dopinghafiza.com/src/views/score/components/ScoreMain.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/dopinghafiza.com/src/views/score/score-calculation/components/CalcALES.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/dopinghafiza.com/src/views/score/score-calculation/components/CalcAYT.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/dopinghafiza.com/src/views/score/score-calculation/components/CalcDGS.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/dopinghafiza.com/src/views/score/score-calculation/components/CalcKPSS.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/dopinghafiza.com/src/views/score/score-calculation/components/CalcLGS.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/dopinghafiza.com/src/views/score/score-calculation/components/CalcTYT.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/dopinghafiza.com/src/views/score/score-calculation/components/CalcYDT.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/dopinghafiza.com/src/views/standard/components/StdPageMain.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/dopinghafiza.com/src/views/testimonials/components/TestimonialsMain.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/dopinghafiza.com/src/views/testimonials/testimonial-categories/components/TestimonialCategoryMain.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/dopinghafiza.com/src/views/testimonials/testimonial-categories/testimonial-detail/components/TestimonialDetailMain.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/dopinghafiza.com/src/views/videos/components/SampleVideos.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/dopinghafiza.com/src/views/videos/video-categories/components/SampleVideoCategory.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/dopinghafiza.com/src/views/videos/video-categories/video-sub-categories/components/SampleVideoSubCategory.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/dopinghafiza.com/src/views/videos/video-categories/video-sub-categories/video-detail/components/VideoDetailMain.tsx");
